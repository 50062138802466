import React, { useState, useEffect } from "react";
import { Button, Box, Container, List, ListItem, ListItemText, Paper } from '@mui/material';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import HeaderComponent from "./Components/HeaderComponent";
import { axiosGet, axiosPost } from "./utils/axios";


function Home({ email, signOut }) {
  const BASE_URL = "https://api.viking-to-centurion.com"

  let userEmail = email;

  let [games, setGames] = useState([]);

  let navigate = useNavigate(); 

  useEffect(() => {
    async function fetchData() {
      const response = await axiosGet(BASE_URL+ `/v1/games?userEmail=${userEmail}`);
      console.log(response.data)
      setGames(response.data);
    }

    fetchData();
  }, []);

  const startNewGame = () => {
    console.log('Starting a new game...');

    const gameId = uuidv4();

    axiosPost(BASE_URL+ '/v1/games', {
      gameId: gameId,
      userEmail: userEmail,
    });

    navigate(`/game/${gameId}`);
  };

  const goToGame = (game) => {
    console.log(`Navigating to ${game}`);
    navigate(`/game/${game.gameId}`);
  };

  return (
    <Container>
      <HeaderComponent signOut={signOut} pageName={"Home"}/>
      <Box display="flex" flexDirection="column" alignItems="center" height="100vh">
        
        <Button variant="contained" color="primary" onClick={startNewGame} sx={{ width: "100%", marginBottom: 3 }}>
          Start New Game
        </Button>

        <Box>
          <h2>Previous Games</h2>
          <List>
            {games.map((game, index) => (
              <Paper elevation={3} key={index} style={{ marginBottom: '10px' }}>
                <ListItem button onClick={() => goToGame(game)}>
                  <ListItemText primary={`Game Id: ${game.gameId}`} />
                </ListItem>
              </Paper>
            ))}
          </List>
        </Box>

      </Box>
    </Container>
  );
}

export default Home;
