import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from "chart.js";

import './StockChart.css';

// Register the necessary components and plugins from Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true
    }
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'StockChart',
    },
  },
};

// Generating labels from 1993 to 2023
const labels = Array.from({ length: 31 }, (_, i) => (1993 + i).toString());

const returns = [0, -0.0154, 0.3411, 0.2026, 0.3101, 0.2667, 0.1953, -0.1014, -0.1304, -0.2337, 0.2638, 0.0899, 0.03, 0.1362, 0.0353, -0.3849, 0.2345, 0.1278, 0, 0.1341, 0.296, 0.1139, -0.0073, 0.0954, 0.1942, -0.0624, 0.2888, 0.1626, 0.2689, -0.1944, 0.109]; 

let investment = 1000000;

const spInvestment = returns.map((rate) => {
  investment *= (1 + rate);
  return investment;
});

const StockChart = (props) => {
    const data = {
        labels: labels,
        datasets: [
          {
            label: "Example Stock Prices",
            data: props.data,
            borderColor: "#88b8e3ff",
            backgroundColor: "#88b8e3ff",
            fill: {
              target: 'start',
              above: "#e1eaf3ff",
            },
            order: 2
          },
          {
            label: "S&P 500 Investment",
            data: spInvestment,
            borderColor: "#e3645aff",
            backgroundColor: "#e3645aff",
            order: 1
          }
        ],
    };

    return (
      <div className="chart-container">
        <Line options={options} data={data} />
      </div>
    );
};

export default StockChart;
