import React, { useState, useEffect } from "react";
import StockChart from "./Components/StockChart";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useParams } from "react-router-dom";
import HeaderComponent from "./Components/HeaderComponent";
import { Auth } from 'aws-amplify';
import { axiosGet, axiosPost } from "./utils/axios";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



const BASE_URL = "https://api.viking-to-centurion.com"

export default function Game({ signOut }) {
    const [bondPercentage, setBondPercentage] = useState(50);
    const [equityPercentage, setEquityPercentage] = useState(50);
    const [inputError, setInputError] = useState(false);
    const [fullDataArray, setFullDataArray] = useState([]);
    const [valueEachYear, setValueEachYear] = useState([1000000]);

    let { gameId } = useParams();

    useEffect(() => {
      async function fetchData() {
          try {
              const response = await axiosGet(BASE_URL + `/v1/gameRecord?gameId=${gameId}`);

              if(response.data.length === 0) {
                setFullDataArray([{
                  gameId: gameId,
                  year: 1993,
                  value: 1000000,
                  bondPercentage: 50,
                  equityPercentage: 50
                }]);
                return;
              }

              if(response.data[0].year !== 1993) {
                setFullDataArray([{
                  gameId: gameId,
                  year: 1993,
                  value: 1000000,
                  bondPercentage: 50,
                  equityPercentage: 50
                }]);
                return;
              }

              setFullDataArray(response.data);

          } catch (error) {
              console.error('There was an error fetching the game data:', error);
          }
      }

      fetchData();
    }, []);

    function sendToAws() {
      axiosPost(BASE_URL+ '/v1/gameRecord', fullDataArray);
    }

    useEffect(() => {
      if(fullDataArray.length === 0) return;

      let valueEachYearArray = [];
      for(let i = 0; i < fullDataArray.length; i++) {
        valueEachYearArray.push(fullDataArray[i].value);
      }
      setValueEachYear(valueEachYearArray);

      console.log(fullDataArray);

      sendToAws();
    }, [fullDataArray]);

    useEffect(() => {
      console.log(valueEachYear.length);
      if (Number(bondPercentage) + Number(equityPercentage) === 100 && Number(bondPercentage) >= 0 && Number(equityPercentage) >= 0 && valueEachYear.length <= 30) {
        setInputError(false);
      } else {
        setInputError(true);
      }
    }, [bondPercentage, equityPercentage, valueEachYear]);

    function onClickNextYear(valueEachYearArray) {
      const lastValue = Number(valueEachYearArray[valueEachYearArray.length - 1].value);

      console.log(lastValue);
      
      // Calculate bond value and increase by 3%
      const bondValue = (bondPercentage / 100) * lastValue;

      console.log(bondValue);

      const newBondValue = bondValue + (0.03 * bondValue);

      console.log(newBondValue);
      
      // Calculate equity value and increase by 30% or decrease by 10% with 50% probability each
      const equityValue = (equityPercentage / 100) * lastValue;
      const equityChange = Math.random() > 0.5 ? 0.30 : -0.10; // 50% chance to either increase or decrease
      const newEquityValue = equityValue + (equityChange * equityValue);
      
      // Combine the new bond and equity values to get the new total value
      const newValue = newBondValue + newEquityValue;

      let object = {
        gameId: gameId,
        year: valueEachYearArray.length + 1993,
        value: newValue,
        bondPercentage: bondPercentage,
        equityPercentage: equityPercentage
      }

      return [...valueEachYearArray, object];
    }

    function onClickUpToFiveYears() {
      let valueEachYearArray = [...fullDataArray]; // Create a copy to avoid direct state manipulation
      const currentYearCount = valueEachYearArray.length;
      
      // Calculate how many years you can still add without exceeding 30 years
      const yearsRemaining = 31 - currentYearCount;
      
      // Determine the number of years to advance, ensuring it's not more than 5 or the remaining years to reach 30
      const yearsToAdvance = Math.min(yearsRemaining, 5);
    
      // Run the simulation for the calculated number of years to advance
      for (let i = 0; i < yearsToAdvance; i++) {
        valueEachYearArray = onClickNextYear(valueEachYearArray);
      }
      
      setFullDataArray(valueEachYearArray); // Update the state with the new array
    }
    

    function reset() {
      setFullDataArray([{
        gameId: gameId,
        year: 1993,
        value: 1000000,
        bondPercentage: 50,
        equityPercentage: 50
      }]);
    }
  
    return (
      <Box sx={{mr: 2, ml: 2}}>
        <HeaderComponent signOut={signOut} pageName={"Games"}/>
        <h1>Stock Price</h1>
        <TextField
          required={true}
          error={inputError}
          value={bondPercentage}
          onChange={(e) => setBondPercentage(e.target.value)}
          label="Bond Percentage"
          sx={{ height: 56, width: "100%", marginBottom: 3 }}
        />
        <TextField
          required={true}
          error={inputError}
          value={equityPercentage}
          onChange={(e) => setEquityPercentage(e.target.value)}
          label="Equity Percentage"
          sx={{ height: 56, width: "100%", marginBottom: 3 }}
        />
        <Button variant="contained" disabled={inputError} onClick={() => {onClickUpToFiveYears()}} sx={{ height: 56, width: "100%", marginBottom: 3 }}>Go 5 years</Button>
        <Button variant="contained" onClick={() => {reset()}} sx={{ height: 56, width: "100%", marginBottom: 3 }}>Reset</Button>
        <StockChart data={valueEachYear}  />
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell align="right">Value</TableCell>
            <TableCell align="right">Return %</TableCell>
            <TableCell align="right">Bond %</TableCell>
            <TableCell align="right">Equity %</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
  {fullDataArray.map((row, index) => {
    let returnPercentage;
    if (index === 0) {
      returnPercentage = 'N/A';
    } else {
      const currentValue = row.value;
      const previousValue = fullDataArray[index - 1].value;
      const returnAmount = currentValue - previousValue;
      returnPercentage = ((returnAmount / previousValue) * 100).toFixed(2) + '%';
    }

    const valueInMillions = (row.value / 1000000).toFixed(2);

    return (
      <TableRow key={row.year}>
        <TableCell component="th" scope="row">{row.year}</TableCell>
        <TableCell align="right">{`${valueInMillions}M`}</TableCell>
        <TableCell align="right">{returnPercentage}</TableCell>
        <TableCell align="right">{row.bondPercentage}</TableCell>
        <TableCell align="right">{row.equityPercentage}</TableCell>
      </TableRow>
    );
  })}
</TableBody>
      </Table>
    </TableContainer>
      </Box>
    );
}