// App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Game from './Game';
import Home from './Home';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import HeaderComponent from "./Components/HeaderComponent";
import GenerateEmail from "./GenerateEmail"

Amplify.configure(awsconfig);

function App({ user, signOut }) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home email={user.attributes.email} signOut={signOut}/>} />
        <Route path="/game/:gameId" element={<Game signOut={signOut}/>} />
        <Route path="*" element={<NotFound signOut={signOut} />} />
      </Routes>
    </BrowserRouter>
  );
}

function NotFound({ signOut }) {
  return (
    <>
      <HeaderComponent signOut={signOut} pageName={"Page Not Found"}/>
      <div style={styles.centerContainer}>
      <h1 style={styles.heading}>Page Not Found</h1>
      </div>
    </>
  );
}

const styles = {
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    margin: '0px',
    height: '100vh', // take full viewport height
  },
  heading: {
    margin: "0px",
  }
};

export default withAuthenticator(App);
