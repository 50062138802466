import { Auth } from 'aws-amplify';
import axios from 'axios';

export async function axiosGet(url) {
    const currentSession = await Auth.currentSession();
    const token = currentSession.getIdToken().getJwtToken();
    console.log(currentSession)
    return await axios.get(url, {
      headers: {
        'Authorization': token
      }
    });
}

export async function axiosPost(url, data) {
    const currentSession = await Auth.currentSession();
    const token = currentSession.getIdToken().getJwtToken();
    console.log(token);
    return await axios.post(url, data, {
      headers: {
        'Authorization': token
      }
    });
}