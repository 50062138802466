import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";

const HeaderComponent = ({ signOut, pageName }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    let navigate = useNavigate(); 

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const navigateToHome = () => {
        navigate(`/`);
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.header}>
                <Box sx={styles.left}>{pageName}</Box>
                <Box sx={styles.right}>
                    <Button onClick={toggleDropdown}>
                        <MenuIcon sx={{padding: "0px"}}/>
                    </Button>
                </Box>
            </Box>
            {dropdownOpen && (
                <Box sx={styles.dropdown}>
                    <Button fullWidth onClick={navigateToHome} sx={styles.dropdownItem}>
                        <HomeIcon sx={styles.icon} />
                        Home Page
                    </Button>
                    <Button fullWidth onClick={signOut} sx={styles.dropdownItem}>
                        <LogoutIcon sx={styles.icon} />
                        Logout
                    </Button>
                </Box>
            )}
        </Box>
    );
};

const styles = {
    container: {
        width: '100%',
        marginBottom: '20px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '60px',
        backgroundColor: '#FFFFFF',
        paddingLeft: '20px',
        borderBottom: '1px solid #E0E0E0',
    },
    left: {
        fontSize: '18px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
    },
    right: {
        display: 'flex',
        alignItems: 'center',
    },
    dropdown: {
        backgroundColor: 'white',
        border: '1px solid #E0E0E0',
        borderTop: 'none',
        width: '100%',
    },
    dropdownItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 20px',
        justifyContent: 'center',
        borderBottom: '1px solid #E0E0E0',
        '&:hover': {
            backgroundColor: '#F5F5F5',
        },
        '&:last-child': {
            borderBottom: 'none',
        }
    },
    icon: {
        marginRight: '8px',
    }
};

export default HeaderComponent;
